import LoginPage from "./pages/login";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import RegisterPage from "./pages/register";
import PanelMenu from "./pages/menu";

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage/>} />
                <Route path="/register" element={<RegisterPage/>} />
                <Route path="/panel" element={<PanelMenu/>} />
            </Routes>
        </BrowserRouter>
    );
}