import {
    BoxPlotOutlined,
    FileOutlined, FileTextFilled, FileWordOutlined, GroupOutlined,
    ShoppingCartOutlined, SketchOutlined, UsergroupAddOutlined,
    UserOutlined, UserSwitchOutlined
} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, Space, theme} from 'antd';
import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import NotificationHeader from "../utils/notification";
import PanelContent from "./renderPanelContent";

const {Header, Content, Footer, Sider} = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items = [
    getItem('Admin', '-1'),
    // getItem('Configs', '0', <FileWordOutlined />),
    // getItem('Inbox', '1', <FileOutlined/>),
    // getItem('Chests', '2', <BoxPlotOutlined/>),
    // getItem('Season', '3', <SketchOutlined/>),
    // getItem('Avatar', '4', <UserSwitchOutlined/>),
    // getItem('Armies', '5', <GroupOutlined/>),
    // getItem('Shop', '6', <ShoppingCartOutlined/>),

];

function findItem(key) {
    let selectedItems = []
    for (let i = 0; i < items.length; i++) {
        if (items[i].key === key) {
            selectedItems.push(items[i])
            return selectedItems;
        }
        if (items[i].children == null) {
            continue;
        }
        for (let j = 0; j < items[i].children.length; j++) {
            if (items[i].children[j].key === key) {
                selectedItems.push(items[i])
                selectedItems.push(items[i].children[j])

                return selectedItems;
            }
        }
    }
}

export default function PanelMenu() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentItem, setCurrentItem] = useState();
    const [cookies, setCookie] = useCookies(['access_token'])
    const navigation = useNavigate();

    if (cookies.access_token === undefined){
      //  navigation("/")
    }

    const {
        token: {colorBgContainer},
    } = theme.useToken();


    useEffect(()=>{
        OnSelectItem(items[0])

    },[])


    function OnSelectItem (item) {
        setCurrentItem(() => {
            return item.key
        })
        let selectedItems = findItem(item.key)
        let labels = []
        selectedItems.map((item) => {
            labels.push(item.label);
            labels.push("/")
        })
        labels.pop(labels[labels.length - 1]);
        setSelectedItems(() => {
            return labels
        });
    }


    return (
        <>
            <NotificationHeader></NotificationHeader>
            <Layout
                style={{
                    maxHeight: 500,
                    minHeight: '100vh',
                }}
            >
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div
                        style={{
                            overflow: 'auto',
                            height: 32,
                            margin: 16,
                            position: 'relative',
                            left: 0,
                            background: 'rgba(255, 255, 255, 0.2)',
                        }}
                    />
                    <Menu onSelect={OnSelectItem} theme="dark" defaultSelectedKeys={['-1']} mode="inline" items={items}/>
                </Sider>
                <Layout>
                    <Content
                        style={{
                            overflow:'auto',
                            margin: '0 16px',
                            maxHeight:'100%',
                        }}
                    >
                        <Breadcrumb
                            style={{
                                margin: '16px 0',
                            }}>
                            {selectedItems}
                            {/*{<Breadcrumb.Item>Bill</Breadcrumb.Item>}*/}
                        </Breadcrumb>
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                            }}
                        >
                            <PanelContent item={currentItem}></PanelContent>
                        </div>
                    </Content>

                </Layout>

            </Layout>
        </>

    );
};
