import { Button, Input, InputNumber, Space } from "antd";
import axios, {formToJSON} from "axios";
import { AppConstants, CustomHeaderToken, GetWebServiceUrl } from "../utils/constants";
import NotificationHeader, { SetNotificationWithoutDismiss } from "../utils/notification";
import { useCookies } from "react-cookie";
import {useEffect, useState} from "react";
import {json} from "react-router-dom";
const { TextArea } = Input;

async function edit(userType,data,cache){
    console.log("EDIT", data)
    let request = {
        "type":1,
        "UserType":userType,
        "body":JSON.parse(data)
    }

    console.log(request)
    let response = await axios.post(GetWebServiceUrl(AppConstants.CONFIG), request, CustomHeaderToken(cache.access_token)).catch(err => {
        console.log("ERR", err)
        SetNotificationWithoutDismiss("Error", err.response.status + "\n" + JSON.stringify(err.response.data.message), "danger");
    })

    return response;
}

async function fetchData(cache){
    console.log("FETCH_DATA")
    let request = {
        "type":0,
    }
    let response = await axios.post(GetWebServiceUrl(AppConstants.CONFIG), request, CustomHeaderToken(cache.access_token)).catch(err => {
        console.log("ERR", err)
        SetNotificationWithoutDismiss("Error", err.response.status + "\n" + JSON.stringify(err.response.data.message), "danger");
    })

    return response;
}


export default function AdminContent() {
    const [cookies, setCookie] = useCookies(['access_token'])
    const [data, setData] = useState([{userType: 0,steps:[{"score":0,"reward":0},{"score":0,"reward":0}]},{userType: 1,steps:[{"score":1000,"reward":1},{"score":10000,"reward":10}]}], )
    const [dataZ, setDataZ] = useState({ })
    const [dataO, setDataO] = useState({ })

    useEffect(() => {
        let response = fetchData(cookies).then(res => {
            setData(res.data)
            console.log("GET_DATA",data)
        })
    }, [])
    let example = '[{"score":1000,"reward":1},{"score":10000,"reward":10}]'
    return (
        <>
            <NotificationHeader></NotificationHeader>
            <div style={{width: "100%"}}>
                <p>Example</p>
                <p>{example}</p>
            </div>
            <div style={{width: "100%"}}>
                <h2>UserType {JSON.stringify(data[0].userType)}</h2>
                <p>{JSON.stringify(data[0].steps)}</p>
                <h2>UserType {JSON.stringify(data[1].userType)}</h2>
                <p>{JSON.stringify(data[1].steps)}</p>

            </div>

            <div style={{width: "100%"}}>
                <h1>new user</h1>
                <TextArea
                    placeholder='[{"score":1000,"reward":1},{"score":10000,"reward":10}]'
                    autoSize={{minRows: 1, maxRows: 100}}
                    onChange={
                        (e) => {
                            console.log(e.target.value);
                            setDataZ(e.target.value);
                        }}
                />
                <Button type="primary" onClick={() => edit(0, dataZ, cookies)}>Save</Button>
            </div>

            <div style={{width: "100%"}}>
                <h1>registered user</h1>
                <TextArea
                    placeholder='[{"score":1000,"reward":1},{"score":10000,"reward":10}]'
                    autoSize={{minRows: 1, maxRows: 100}}
                    onChange={(e) => {
                        setDataO(e.target.value)
                    }}
                />
                <Button type="primary" onClick={() => edit(1, dataO, cookies)}>Save</Button>
            </div>
        </>
    )
}