import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";

const renderTitle = (title) => (
    <span>
    {title}
        <a
            style={{
                float: 'right',
            }}
            href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
        >
    </a>
  </span>
);
const renderItem = (value, name, icon) => ({
    value: value,
    label: (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {name}
            <span>
        {icon}
      </span>
        </div>
    ),
});


const data = [{"value": false, "name": "false"}, {"value": true, "name": "true"}]


export function GetBoolValue(name) {
    for (const datum of data) {
        if (datum.name === name) {
            return datum.value
        }
    }

    return false
}

export function GetBoolName(value) {
    for (const datum of data) {
        if (datum.value === value) {
            return datum.name
        }
    }

    return ""
}

const list = () => {
    let renderItems = []
    data.map((datum) => {
        let icon = <CheckCircleOutlined/>
        switch (datum.value) {
            case false:
                icon = <CloseCircleOutlined/>
                break
        }

        renderItems.push(renderItem(datum.value, datum.name, icon))
    })
    return renderItems
}


const BooleansOptions = [
    {
        label: renderTitle('Booleans'),
        options: list(),
    },
];

export default BooleansOptions