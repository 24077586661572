export const AppConstants = {
    SITE_ADDR: "http://localhost:3000",
    WEB_SERVICE_URL: "https://bazinex-api.ramzinex.com/admin",
    LOGIN: "/user/login",
    REGISTER: "/user/register",
    CONFIG : "/score/action",
    User: "/user"
}

export function GetWebServiceUrl(path) {
    return AppConstants.WEB_SERVICE_URL + path;
}


const GetAccessToken = () => {
    console.log("GET ACCESS TOKEN")
    let cookies = document.cookie.split(";")
    for (const cookie of cookies) {
        let c = cookie.split("=")
        if (c[0] === "access_token") {
            return c[1]
        }
    }
    return ""
}

export function GetBasicAuthHeader() {
    return {
        headers: {'token': GetAccessToken()}
    }
}

export function GetUploadAuthHeader() {
    return {
        'token': GetAccessToken()
    }
}

export function CustomHeaderToken(token){
    return {
        headers: { Authorization: `Bearer ${token}` }
    }
}

export function LoginBasicAuth(username, password) {
    return {
        auth: {
            username: username,
            password: password
        }
    }
}