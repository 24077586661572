import {Button, Input, Space} from "antd";
import {Content} from "antd/es/layout/layout";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import axios from "axios";
import {AppConstants, GetWebServiceUrl, LoginBasicAuth} from "../utils/constants";
import NotificationHeader, {SetNotificationWithoutDismiss} from "../utils/notification";
import {useCookies} from "react-cookie";

let username;
let password;
export default function LoginPage() {
    const [cookies, setCookie] = useCookies(['access_token'])
    const [loadings, setLoadings] = useState([]);
    const [errors, setErrors] = useState([]);

    const navigation = useNavigate();
    const register = () => {
        navigation("/register")
    }
    const onChangeUsername = (value) => {
        username = value;

    }

    const onChangePassword = (value) => {
        password = value;
    }

    const login = async () => {
        clearErrors()
        console.log("login")
        if (username === undefined || username === "") {
            addError(0)
            return
        }
        if (password === undefined || password === "") {
            addError(1)
            return
        }
        enterLoading(0);
        let response = await axios.get(GetWebServiceUrl(AppConstants.LOGIN), LoginBasicAuth(username, password)).catch(err => {
            SetNotificationWithoutDismiss("Error", err.response.status + "\n" + JSON.stringify(err.response.data.message), "danger");
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[0] = false;
                return newLoadings;
            });
            return
        })

        if (response !== undefined && response.status === 200) {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[0] = false;
                return newLoadings;
            });

            let expires = new Date()
            expires.setTime(expires.getTime() + (response.data.expiredAt * 1000))
            setCookie('access_token', response.data.token, {path: '/', expires})

            if (response.data.role == 0) {
                navigation("/activation");
            } else {
                navigation("/panel");
            }
            console.log(response)
        }
    }

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };
    const addError = (index) => {
        console.log(index)
        setErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = "error";
            return newErrors;
        });
    };

    const clearErrors = () => {
        setErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            for (let i = 0; i < newErrors.length; i++) {
                newErrors[i] = ""
            }
            return newErrors
        });
    };
    return (
        <>
            <NotificationHeader></NotificationHeader>
            <div style={{
                padding: 0,
                height: "100vh",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div style={{minWidth: "30%"}}>
                    <h1 style={{textAlign: "center"}}>Bazinex <span style={{color: "red"}}>Admin</span></h1>
                    <Content style={{margin: "auto"}}>
                        <Space direction="vertical" style={{width: "100%", margin: "auto",}}>
                            <Input onChange={(e) => onChangeUsername(e.target.value)} status={errors[0]}
                                   prefix={<UserOutlined/>} placeholder="Username"/>
                            <Input.Password onChange={(e) => onChangePassword(e.target.value)} status={errors[1]}
                                            prefix={<LockOutlined/>} placeholder="Password"/>
                            <Button loading={loadings[0]} onClick={() => login()} size={'middle'} type={"primary"}
                                    style={{width: "100%"}}>Login</Button>
                            <Button onClick={register} size={'middle'} type={"link"}
                                    style={{width: "100%"}}>Register</Button>
                        </Space>
                    </Content>
                </div>
            </div>
        </>
    )
}