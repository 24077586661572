import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl, LoginBasicAuth} from "../utils/constants";
import {SetNotificationWithoutDismiss} from "../utils/notification";
import {useRef, useState} from "react";
import ConfigContent from "./configContent";
import AdminContent from "./adminContent";

export let ConfigData = []

export default function PanelContent({item}) {
    const [refresh, setRefresh] = useState(0)
    const Refresh = async () => {
        setRefresh(refresh + 1)
    }

    switch (item) {
        case '-1':
            return (<AdminContent onChange={() => Refresh()}></AdminContent>)
            break
    }


    return (
        <></>
    )
}